// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  transition: all ease-in-out 500ms;
}

.custom-table th {
  border-bottom: 1px solid #3e1660 !important;
  text-align: left;
  background-color: white !important;
  color: #3e1660 !important;
  padding: 16px 10px !important;
  position: sticky;
  top: 0;
}

.custom-table td {
  border-bottom: 1px solid #e4e7ec;
  text-align: left !important;
  padding: 16px 10px !important;
  color: var(--color-neutral700);
}

.custom-table .inactive {
  background-color: white;
}

.custom-table .service-id {
  color: var(--color-success);
}

.custom-table .active-status {
  margin-left: 8px;
  color: green;
}

.custom-table .inactive {
  background-color: #fef3f2;
}

.custom-table .inactive td {
  color: #b42318 !important;
}

.custom-table .sucess td {
  background-color: #f5fef2;
}

.custom-table .sucess td {
  color: var(--color-success) !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/LoadCsv/styles/Table.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,cAAc;EACd,iCAAiC;AACnC;;AAEA;EACE,2CAA2C;EAC3C,gBAAgB;EAChB,kCAAkC;EAClC,yBAAyB;EACzB,6BAA6B;EAC7B,gBAAgB;EAChB,MAAM;AACR;;AAEA;EACE,gCAAgC;EAChC,2BAA2B;EAC3B,6BAA6B;EAC7B,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".custom-table {\n  width: 100%;\n  border-collapse: collapse;\n  overflow: auto;\n  transition: all ease-in-out 500ms;\n}\n\n.custom-table th {\n  border-bottom: 1px solid #3e1660 !important;\n  text-align: left;\n  background-color: white !important;\n  color: #3e1660 !important;\n  padding: 16px 10px !important;\n  position: sticky;\n  top: 0;\n}\n\n.custom-table td {\n  border-bottom: 1px solid #e4e7ec;\n  text-align: left !important;\n  padding: 16px 10px !important;\n  color: var(--color-neutral700);\n}\n\n.custom-table .inactive {\n  background-color: white;\n}\n\n.custom-table .service-id {\n  color: var(--color-success);\n}\n\n.custom-table .active-status {\n  margin-left: 8px;\n  color: green;\n}\n\n.custom-table .inactive {\n  background-color: #fef3f2;\n}\n\n.custom-table .inactive td {\n  color: #b42318 !important;\n}\n\n.custom-table .sucess td {\n  background-color: #f5fef2;\n}\n\n.custom-table .sucess td {\n  color: var(--color-success) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
