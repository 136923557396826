import { Skeleton } from "@enerbit/base";
import React from "react";
import { Invitation } from "../../../models/invitations";
import "../styles/NotificationCard.css";

interface NotificationCardProps {
  invitation: Invitation;
}

export const NotificationCard: React.FC<NotificationCardProps> = ({
  invitation,
}) => {
  const _invitationTypeToUIMessage = (): string => {
    switch (invitation.invitation_type.code) {
      case "change_owner":
        return "a ser el nuevo propietario de ";
      case "add_stakeholder":
        return "a participar en el servicio de ";
      case "add_admin":
        return "a ser el nuevo administrador del servicio ";
      case "add_change_billable":
        return "a ser pagar las facturas de ";
      default:
        return "a ";
    }
  };

  return (
    <div className="notification-card">
      <div
        className={`icon ${
          invitation.last_invitation_state.state.code == "rejected"
            ? "error"
            : "success"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          viewBox="0 96 960 960"
          width="20"
          fill="currentColor"
        >
          <path d="m274 831 516-274-516-274v172L623 558 274 658v173Zm-94 85V347q0-30 26.5-45.5T224 282l630 316q28 14 28 45t-28 45L224 1004q-28 14-54.5-1.5T180 957Z" />
        </svg>
      </div>
      <div className="text-container">
        <span className="strong-text">{invitation.recipient_email}</span> ha
        sido invitado {_invitationTypeToUIMessage()}
        <span className="strong-text">
          {invitation.estate_address ??
            invitation.personalization ??
            invitation.service_account_id}
        </span>
      </div>
    </div>
  );
};

export const NotificationCardSkeleton = () => {
  return (
    <>
      <Skeleton height={100} />
      <Skeleton height={100} />
    </>
  );
};
