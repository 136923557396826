import { Box, Grid } from "@enerbit/base";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadInvitation } from "../../features/actions/invitations.actions";
import {
  onInvitationErrorsChanged,
  onInvitationsToSendChanged,
} from "../../features/invitations/invitations";
import { StateStorage } from "../../models/state-storage";
import { AppDispatch } from "../../store/store";
import UploadCard from "../Dashboard/components/UploadCard";
import ErrorLogs from "./components/ErrorLogs";
import Table from "./components/Table";
import "./styles/Table.css";

export const LoadCsv = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { invitationsToSend } = useSelector(
    (state: StateStorage) => state.invitationsState
  );

  useEffect(() => {
    // const beforeCloseWindow = (event) => {
    //   const message =
    //     "Estás seguro de querer cerrar la página? Tal vez se pierdan algunos datos";
    //   event.returnValue = message;
    //   return message;
    // };
    // window.addEventListener("beforeunload", beforeCloseWindow);
    // return () => window.removeEventListener("beforeunload", beforeCloseWindow);
  }, []);
  useEffect(() => {
    if (invitationsToSend.every((value) => value.was_created == null)) {
      for (let index = 0; index < invitationsToSend.length; index++) {
        if (invitationsToSend[index].was_created == null) {
          dispatch(uploadInvitation(invitationsToSend[index]));
        }
      }
    }
  }, [invitationsToSend]);

  useEffect(() => {
    return () => {
      dispatch(onInvitationsToSendChanged([]));
      dispatch(onInvitationErrorsChanged([]));
    };
  }, []);

  return (
    <Grid container sx={{ maxHeight: "84vh" }} padding={0}>
      <Grid item md={9} xs={4} xl={9}>
        <Box marginTop={6} marginLeft={6} marginRight={6}>
          <Box marginBottom={2}>
            <UploadCard />
            {/* <UploadProgress
              fileName="Torre1_edificio_avanzza.csv"
              fileSize={"3MB"}
              progress={currentProgress}
            /> */}
          </Box>
          <Box height={"70vh"} overflow={"auto"}>
            <Table />
          </Box>
        </Box>
      </Grid>
      <Grid item md={3} xs={8} padding={0}>
        <Box marginTop={6} marginRight={6} height="100%">
          <ErrorLogs />
        </Box>
      </Grid>
    </Grid>
  );
};
