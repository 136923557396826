import { enerbitColors } from "@enerbit/base";
import Papa from "papaparse";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { v4 } from "uuid";
import Card from "../../../common/components/Card";
import OutlinedButton from "../../../common/components/OutlinedButton";
import { onInvitationsToSendChanged } from "../../../features/invitations/invitations";
import { CsvInvitation } from "../../../models/invitations";
import { StateStorage } from "../../../models/state-storage";
import { AppDispatch } from "../../../store/store";

const iconStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  backgroundColor: enerbitColors.primary[600],
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  justifyContent: "center",
  color: "#fff",
};

const textStyle: React.CSSProperties = {
  marginLeft: "15px",
  color: enerbitColors.primary[600],
  fontWeight: "bold",
  fontSize: "16px",
};
const initialHeaders = [
  "service_account_id",
  "address",
  "owner_id",
  "new_owner_name",
  "new_owner_lastname",
  "new_owner_email",
  "new_owner_phone",
];
const UploadCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isFirstRender = useRef(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<CsvInvitation[]>([]);
  const { invitationsToSend } = useSelector(
    (state: StateStorage) => state.invitationsState
  );

  useEffect(() => {
    return () => {
      if (location.pathname == "/load-csv") {
        setData([]);
      }
    };
  }, [location.hash]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (data.length <= 0) {
      Swal.fire({
        title: "Ups",
        icon: "error",
        text: "El archivo que estás intentando subir se encuentra vacio, por favor verifícalo",
      });
      return;
    }
    const headers = Object.keys(data[0]);
    if (!initialHeaders.every((value) => headers.includes(value))) {
      Swal.fire({
        title: "Ups",
        icon: "error",
        text: `Verifica que el archivo contiene el formato indicado, debes de incluir las siguientes columnas en el:\n 
                    - service_account_id \n
                    - address \n
                    - owner_id \n
                    - new_owner_name \n
                    - new_owner_lastname \n
                    - new_owner_email \n
                    - new_owner_phone`,
      });
      return;
    }
    const invitationsToSend = data.map((value) => ({
      id: v4(),
      was_created: null,
      ...value,
    }));
    dispatch(onInvitationsToSendChanged(invitationsToSend));
    if ((location.pathname = "/")) {
      navigate("/load-csv");
    }
  }, [data]);

  const _onUploadCSVClicked = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = () => {
    const file = fileInputRef.current?.files?.[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result: { data: CsvInvitation[] }) => {
          setData(result.data);
        },
        error: (error) => {
          console.error("Error al leer el archivo CSV:", error);
        },
      });
    }
  };

  return (
    <Card
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={iconStyle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 96 960 960"
            width="24"
            fill="currentColor"
          >
            <path d="M480 936q-125 0-212.5-87.5T180 636q0-118 74.5-194.5T445 344q7-29 30-47t55-18q34 0 57 18t30 47q116 10 190.5 86.5T780 636q0 124-87.5 211.5T480 936Zm0-60q100 0 170-70t70-170q0-97-67-164.5T488 404h-16q-100 7-168 74.5T236 636q0 100 70 170t170 70Zm0-310Z" />
          </svg>
        </div>
        <div style={textStyle}>Nuevo cambio masivo de owner</div>
      </div>
      <input
        type="file"
        accept=".csv"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <OutlinedButton onClick={_onUploadCSVClicked}>Subir csv</OutlinedButton>
    </Card>
  );
};

export default UploadCard;
