import { Skeleton } from "@enerbit/base";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvitationsHistory } from "../../../features/actions/invitations.actions";
import { StateStorage } from "../../../models/state-storage";
import { AppDispatch } from "../../../store/store";
import "../styles/TabSection.css";
import { NotificationCard, NotificationCardSkeleton } from "./NotificationCard";
import { Tabs } from "./Tabs";

const TabsSection = () => {
  const [activeTab, setActiveTab] = useState("Todas");
  const { invitationsHistory, isRequestingHistory } = useSelector(
    (state: StateStorage) => state.invitationsState
  );
  const nextPageRef = useRef<number | null>(null);
  const isRequesting = useRef<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const scrollDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(
      getInvitationsHistory({
        page: 0,
        size: 16,
        reset: true,
      })
    );
  }, []);

  useEffect(() => {
    isRequesting.current = isRequestingHistory;
  }, [isRequestingHistory]);

  useEffect(() => {
    nextPageRef.current = invitationsHistory.next_page;
  }, [invitationsHistory]);

  const handleScroll = () => {
    const scrollDiv = scrollDivRef.current;
    if (scrollDiv) {
      const { scrollTop, scrollHeight, clientHeight } = scrollDiv;
      const scroll = scrollTop + clientHeight;
      const currentNextPage = nextPageRef.current;
      const isRequestingH = isRequesting.current;
      if (scroll >= scrollHeight - 60 && !isRequestingH && currentNextPage) {
        dispatch(
          getInvitationsHistory({
            page: currentNextPage,
            size: 16,
          })
        );
      }
    }
  };

  useEffect(() => {
    const scrollDiv = scrollDivRef.current;
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const filteredNotifications = invitationsHistory.items.filter(
    (notification) => {
      if (activeTab === "Todas") return true;
      if (activeTab === "Aceptadas")
        return notification.last_invitation_state.state.code === "success";
      if (activeTab === "Rechazadas")
        return notification.last_invitation_state.state.code === "rejected";
      if (activeTab === "Pendientes")
        return notification.last_invitation_state.state.code === "created";
      return true;
    }
  );

  return (
    <div style={{ width: "100%" }}>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="notifications-container" ref={scrollDivRef}>
        {isRequestingHistory && filteredNotifications.length == 0 ? (
          <>
            <Skeleton height={100} />
            <Skeleton height={100} />
          </>
        ) : (
          filteredNotifications.map((notification, index) => {
            if (
              index == filteredNotifications.length - 1 &&
              isRequestingHistory
            ) {
              return (
                <>
                  <NotificationCard invitation={notification} key={index} />
                  <NotificationCardSkeleton key="loading" />
                </>
              );
            }
            return <NotificationCard invitation={notification} key={index} />;
          })
        )}
      </div>
    </div>
  );
};

export default TabsSection;
