// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  height: fit-content;
  max-height: 50vh;
  overflow-y: scroll;
}

.tab-button {
  color: grey;
  border-radius: 8px;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: white;
  font-weight: normal;
  border: none;
}

.tab-button.active {
  color: var(--color-primary);
  background-color: #f3ebff;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/styles/TabSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".notifications-container {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-gap: 15px;\n  height: fit-content;\n  max-height: 50vh;\n  overflow-y: scroll;\n}\n\n.tab-button {\n  color: grey;\n  border-radius: 8px;\n  margin-right: 10px;\n  padding: 10px;\n  cursor: pointer;\n  background-color: white;\n  font-weight: normal;\n  border: none;\n}\n\n.tab-button.active {\n  color: var(--color-primary);\n  background-color: #f3ebff;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
