// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-history-card .title {
  color: var(--color-primary);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/styles/ActionHistory.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".action-history-card .title {\n  color: var(--color-primary);\n  font-size: 18px;\n  font-weight: bold;\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
