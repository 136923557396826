// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-card {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  height: 95px;
}

.notification-card .icon {
  background-color: #f3ebff;
  border-radius: 4px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: var(--color-primary);
}

.notification-card .icon.error {
  background-color: var(--color-error500);
  color: #ffffff;
}

.notification-card .icon.success {
  background-color: var(--color-success);
  color: #ffffff;
}

.notification-card .text-container {
  font-size: 14px;
  color: #333;
}

.notification-card .strong-text {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/styles/NotificationCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,uCAAuC;EACvC,cAAc;AAChB;;AAEA;EACE,sCAAsC;EACtC,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,2BAA2B;AAC7B","sourcesContent":[".notification-card {\n  display: flex;\n  align-items: center;\n  background-color: #fff;\n  border: 1px solid #e0e0e0;\n  border-radius: 8px;\n  padding: 15px;\n  width: 100%;\n  height: 95px;\n}\n\n.notification-card .icon {\n  background-color: #f3ebff;\n  border-radius: 4px;\n  min-width: 40px;\n  width: 40px;\n  height: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 10px;\n  color: var(--color-primary);\n}\n\n.notification-card .icon.error {\n  background-color: var(--color-error500);\n  color: #ffffff;\n}\n\n.notification-card .icon.success {\n  background-color: var(--color-success);\n  color: #ffffff;\n}\n\n.notification-card .text-container {\n  font-size: 14px;\n  color: #333;\n}\n\n.notification-card .strong-text {\n  font-size: 14px;\n  font-weight: bold;\n  color: var(--color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
