// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outlined-button {
  background-color: #fff;
  color: var(--color-secondary500);
  padding: 5px 15px;
  border: 1px solid var(--color-secondary500);
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/common/styles/OutlinedButton.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gCAAgC;EAChC,iBAAiB;EACjB,2CAA2C;EAC3C,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".outlined-button {\n  background-color: #fff;\n  color: var(--color-secondary500);\n  padding: 5px 15px;\n  border: 1px solid var(--color-secondary500);\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 14px;\n  font-weight: bold;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
