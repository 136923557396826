import { configureStore } from "@reduxjs/toolkit";
import invitationsReducer from "../features/invitations/invitations";

export const store = configureStore({
  reducer: {
    invitationsState: invitationsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
