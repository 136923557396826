import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUsersByPattern } from "../../../features/actions/users.actions";
import { AppDispatch } from "../../../store/store";

export const SearchUser = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [inputValue, setInputValue] = useState<string | null>(null);

  const _handleChange = (value: string) => {
    if (value != null) {
      dispatch(
        getUsersByPattern({
          page: 0,
          pattern: value == "" ? " " : value,
          size: 10,
          reset: true,
        })
      );
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      _handleChange(inputValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);
  return (
    <div className="search-box">
      <input
        type="text"
        placeholder="Buscar por usuario..."
        className="input"
        onChange={(event) => setInputValue(event.target.value ?? "")}
      />
    </div>
  );
};
