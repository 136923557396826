// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #d9d2e6;
  border-radius: 4px;
  font-size: 14px;
  color: grey;
  background-color: #ffffff;
}

.input:focus {
  outline: none;
  border-color: #beabe2;
}
`, "",{"version":3,"sources":["webpack://./src/common/styles/Input.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".input {\n  width: 100%;\n  padding: 10px 14px;\n  border: 1px solid #d9d2e6;\n  border-radius: 4px;\n  font-size: 14px;\n  color: grey;\n  background-color: #ffffff;\n}\n\n.input:focus {\n  outline: none;\n  border-color: #beabe2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
