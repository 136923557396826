import { Box, Grid } from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersByPattern } from "../../features/actions/users.actions";
import { StateStorage } from "../../models/state-storage";
import { AppDispatch } from "../../store/store";
import {
  ConstructorCard,
  ConstructorCardSkeleton,
} from "./components/ConstructorCard";
import SearchInvitationsByUser from "./components/SearchInvitationsByUsers";
import { SearchUser } from "./components/SearchUser";
import SectionCard from "./components/SectionCard";
import TabsSection from "./components/TabSection";
import UploadCard from "./components/UploadCard";

const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { isRequestingUsers, usersPagination } = useSelector(
    (state: StateStorage) => state.invitationsState
  );

  useEffect(() => {
    dispatch(
      getUsersByPattern({
        page: 0,
        pattern: " ",
        size: 10,
        reset: true,
      })
    );
  }, []);
  return (
    <Grid container sx={{ maxHeight: "84vh" }} padding={0}>
      <Grid item md={12} xs={4}>
        <Box marginTop={6} marginLeft={6} marginRight={6}>
          <Box marginBottom={2}>
            <UploadCard />
          </Box>

          <SectionCard title="Descargar plantilla" actions={<SearchUser />}>
            <Box
              sx={{
                display: "flex",
                overflow: "auto",
                paddingBottom: "4px",
                gap: "16px",
              }}
            >
              {isRequestingUsers && usersPagination.items.length == 0 ? (
                <ConstructorCardSkeleton />
              ) : (
                usersPagination.items.map((user, index) => {
                  if (
                    index == usersPagination.items.length - 1 &&
                    isRequestingUsers
                  ) {
                    return (
                      <>
                        <ConstructorCard user={user} key={user.id} />
                        <ConstructorCardSkeleton key="loading" />;
                      </>
                    );
                  }
                  return <ConstructorCard user={user} key={user.id} />;
                })
              )}
            </Box>
          </SectionCard>
          <Box height={"20px"}></Box>

          <SectionCard
            title="Histórico de aceptación"
            actions={<SearchInvitationsByUser />}
          >
            <Box sx={{ display: "flex", paddingBottom: "4px" }}>
              <TabsSection />
            </Box>
          </SectionCard>
        </Box>
      </Grid>
      {/* <Grid md={3} xs={8} padding={0}>
        <Box marginTop={6} marginRight={6} height="100%">
          <ActionHistory />
        </Box>
      </Grid> */}
    </Grid>
  );
};

export default Dashboard;
