// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-card-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  background: var(--Primary-Gray-25, #fcfcfd);
  width: 291px;
  min-width: 291px;
}

.spacer {
  flex-grow: 1;
}

.title-card {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/styles/ConstructorCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,QAAQ;EACR,kBAAkB;EAClB,2CAA2C;EAC3C,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oBAAoB;EACpB,4BAA4B;EAC5B,gBAAgB;EAChB,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":[".user-card-container {\n  display: flex;\n  padding: 16px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 4px;\n  border-radius: 8px;\n  background: var(--Primary-Gray-25, #fcfcfd);\n  width: 291px;\n  min-width: 291px;\n}\n\n.spacer {\n  flex-grow: 1;\n}\n\n.title-card {\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  -webkit-line-clamp: 2;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
