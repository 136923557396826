import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../../common/styles/Input.css";
import "../../../common/styles/SearchBox.css";
import { getInvitationsHistory } from "../../../features/actions/invitations.actions";
import { AppDispatch } from "../../../store/store";

function SearchInvitationsByUser() {
  const dispatch = useDispatch<AppDispatch>();
  const [inputValue, setInputValue] = useState<string | null>(null);

  const _handleChange = (value: string) => {
    if (value != null) {
      dispatch(
        getInvitationsHistory({
          page: 0,
          size: 12,
          email: value,
          reset: true,
        })
      );
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      _handleChange(inputValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  return (
    <div className="search-box">
      <input
        type="text"
        placeholder="Buscar por usuario..."
        className="input"
        onChange={(event) => setInputValue(event.target.value ?? "")}
      />
    </div>
  );
}

export default SearchInvitationsByUser;
