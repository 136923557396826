// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-card-container .title {
  display: inline-block;
  margin-right: 15px;
  color: var(--color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/styles/SectionCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,2BAA2B;AAC7B","sourcesContent":[".section-card-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.section-card-container .title {\n  display: inline-block;\n  margin-right: 15px;\n  color: var(--color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
