import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CsvInvitation } from "../../models/invitations";

export const uploadInvitation = createAsyncThunk(
  "client/uploadInvitation",
  async (data: CsvInvitation, { rejectWithValue }) => {
    try {
      let dataToSend = {
        service_account_id: data.service_account_id,
        user_recipient_email: data.new_owner_email,
        sender_user_id: data.owner_id,
        invitation_type: "change_owner",
      };
      if (data.new_owner_name) {
        dataToSend["user_recipient_name"] = data.new_owner_name;
      }
      if (data.new_owner_lastname) {
        dataToSend["user_recipient_lastname"] = data.new_owner_lastname;
      }
      if (data.new_owner_phone) {
        dataToSend["user_recipient_phone"] = data.new_owner_phone;
      }
      const response = await api.post(
        "/account-invitations/invitations/create_invitation",
        dataToSend
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const templateError: DefaultError = {
          status: error.status,
          message: error.message,
        };
        return rejectWithValue(templateError);
      }
      throw error;
    }
  }
);

export interface GetInvitationsHistoryProps {
  page: number;
  size: number;
  email?: string;
  reset?: boolean;
}
export const getInvitationsHistory = createAsyncThunk(
  "client/getInvitationsHistory",
  async ({ page, size, email }: GetInvitationsHistoryProps) => {
    const params = {
      page: page,
      size: size,
    };
    if (email) {
      params["recipient_email"] = email;
    }
    const response = await api.get(
      "/account-invitations/invitations/historic",
      {
        params,
      }
    );
    return response.data;
  }
);

export interface DefaultError {
  status?: number;
  message?: string;
}
export const getTemplateByUserId = createAsyncThunk(
  "client/getTemplateByUserId",
  async (user_id: string, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/account-invitations/invitations/template/${user_id}/change-ownership`,
        {
          responseType: "blob",
        }
      );
      const blob = response.data;
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `template_${user_id}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const templateError: DefaultError = {
          status: error.status,
          message: error.message,
        };
        return rejectWithValue(templateError);
      }
      throw error;
    }
  }
);
