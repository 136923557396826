// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  padding: 15px 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/common/styles/Card.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,2CAA2C;EAC3C,8BAA8B;AAChC","sourcesContent":[".card {\n  padding: 15px 20px;\n  background-color: #fff;\n  border-radius: 10px;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\n  font-family: Arial, sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
