import { Box } from "@enerbit/base";
import Card from "../../../common/components/Card";

import "../styles/SectionCard.css";

const SectionCard = ({ title, actions, children }) => {
  return (
    <Card style={{ padding: "20px 20px" }}>
      <div className="section-card-container">
        <Box>
          <h1 className="title">{title}</h1>
        </Box>
        {actions}
      </div>
      {children}
    </Card>
  );
};

export default SectionCard;
