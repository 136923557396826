import "../styles/TabSection.css";

export const Tabs = ({ activeTab, setActiveTab }) => {
  const tabs = ["Todas", "Aceptadas", "Rechazadas", "Pendientes"];

  return (
    <div style={{ marginBottom: "20px" }}>
      {tabs.map((tab, index) => (
        <button
          className={`tab-button ${activeTab === tab ? "active" : ""}`}
          key={index}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};
