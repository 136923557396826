import {
  Box,
  LoadingButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@enerbit/base";
import React from "react";
import { useDispatch } from "react-redux";
import { getTemplateByUserId } from "../../../features/actions/invitations.actions";
import { User } from "../../../models/user";
import { AppDispatch } from "../../../store/store";
import "../styles/ConstructorCard.css";

interface ConstructorCardProps {
  user: User;
}

export const ConstructorCard: React.FC<ConstructorCardProps> = ({ user }) => {
  const dispatch = useDispatch<AppDispatch>();

  const _getFirstPhone = (): string => {
    if (user.pii?.phones?.length > 0) {
      return user.pii.phones[0].phone ?? "";
    }
    return "";
  };
  const _getFullName = (): string => {
    return `${user.pii?.names ?? ""} ${user.pii?.last_names ?? ""}`;
  };

  const _handleCsvDownload = (): void => {
    dispatch(getTemplateByUserId(user.id));
  };

  return (
    <Box className="user-card-container">
      <Tooltip title={_getFullName()} placement="top">
        <Typography className="title-card" variant="h6" fontWeight="bold">
          {_getFullName()}
        </Typography>
      </Tooltip>
      <Typography variant="h6" fontSize="16px" fontWeight="normal">
        {user.username}
      </Typography>
      <Typography variant="h6" fontSize="16px" fontWeight="normal">
        {_getFirstPhone()}
      </Typography>
      <div className="spacer"></div>
      <LoadingButton
        variant="contained"
        color="secondary"
        fullWidth
        onClick={_handleCsvDownload}
        loading={user.isRequestingTemplate ?? false}
        disabled={user.isRequestingTemplate ?? false}
      >
        Descargar CSV
      </LoadingButton>
    </Box>
  );
};

export const ConstructorCardSkeleton = () => {
  return (
    <Box className="user-card-container">
      <Skeleton width="80%" />
      <Skeleton width="100%" />
      <Skeleton width="60%" />
      <Skeleton width="100%" height="80px" />
    </Box>
  );
};
