import { useSelector } from "react-redux";
import Card from "../../../common/components/Card";
import { StateStorage } from "../../../models/state-storage";
import "../../Dashboard/styles/ActionHistory.css";

export default function ErrorLogs() {
  const { invitationsErrors } = useSelector(
    (state: StateStorage) => state.invitationsState
  );

  return (
    <Card className="action-history-card">
      <div className="title">Log de errores</div>

      {invitationsErrors.map((error, index) => {
        return (
          <li key={`error_${index}`} className="action-history-item">
            En el service account ID{" "}
            <span className="company-name" style={{ fontWeight: "bold" }}>
              {error.service_account_id}
            </span>{" "}
            {error.message}
          </li>
        );
      })}
    </Card>
  );
}
