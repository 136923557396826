import '../styles/OutlinedButton.css';

const OutlinedButton = ({ onClick, children }) => {
  return (
    <button className="outlined-button" onClick={onClick}>
      {children}
    </button>
  );
};

export default OutlinedButton;
