import { useSelector } from "react-redux";
import { CsvInvitation } from "../../../models/invitations";
import { StateStorage } from "../../../models/state-storage";

const Table = () => {
  const { invitationsToSend } = useSelector(
    (state: StateStorage) => state.invitationsState
  );

  const thStyle = {
    backgroundColor: "white !important",
  };

  const _getColorText = (csvInvitation: CsvInvitation) => {
    if (
      csvInvitation.was_created == null ||
      csvInvitation.was_created == undefined
    ) {
    }
    if (!csvInvitation.was_created) {
    }
    return;
  };

  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th style={thStyle}>Service account ID</th>
          <th style={thStyle}>Address</th>
          <th style={thStyle}>Emisor ID</th>
          <th style={thStyle}>Nombre nuevo propietario</th>
          <th style={thStyle}>Apellido nuevo propietario</th>
          <th style={thStyle}>Correo nuevo propietario</th>
          <th style={thStyle}>Teléfono nuevo propietario</th>
        </tr>
      </thead>
      <tbody>
        {invitationsToSend.map((item, index) => (
          <tr
            key={index}
            className={
              item.was_created == null
                ? ""
                : item.was_created
                ? "sucess"
                : "inactive"
            }
          >
            <td>
              {item.service_account_id}
              {item.was_created && (
                <span className="active-status">&#x2714;</span>
              )}
            </td>
            <td>{item.address}</td>
            <td>{item.owner_id}</td>
            <td>{item.new_owner_name ?? ""}</td>
            <td>{item.new_owner_lastname ?? ""}</td>
            <td>{item.new_owner_email}</td>
            <td>{item.new_owner_phone ?? ""}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
