import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface GetUsersByPatternProps {
  pattern: string;
  page: number;
  size: number;
  reset?: boolean;
}

export const getUsersByPattern = createAsyncThunk(
  "users/search-user",
  async ({ pattern, page, size }: GetUsersByPatternProps) => {
    const params = {
      page: page,
      size: size,
    };
    if (pattern) {
      params["pattern"] = pattern;
    }
    const response = await api.get("/users/app-users/search", {
      params,
    });
    return response.data;
  }
);
