import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EnerbitPagination } from "@enerbit/base";
import Swal, { SweetAlertOptions } from "sweetalert2";
import {
  CsvInvitation,
  Invitation,
  InvitationError,
  InvitationsState,
} from "../../models/invitations";
import { User } from "../../models/user";
import {
  DefaultError,
  getInvitationsHistory,
  GetInvitationsHistoryProps,
  getTemplateByUserId,
  uploadInvitation,
} from "../actions/invitations.actions";
import {
  getUsersByPattern,
  GetUsersByPatternProps,
} from "../actions/users.actions";

const initialState: InvitationsState = {
  isUploadingInvitation: false,
  invitationsToSend: [],
  invitationsErrors: [],
  invitationsHistory: {
    items: [],
    page: 0,
    pages: 0,
    size: 0,
    total: 0,
    next_page: 0,
  },
  isRequestingHistory: false,
  isRequestingUsers: false,
  usersPagination: {
    items: [],
    page: 0,
    pages: 0,
    size: 0,
    total: 0,
    next_page: 0,
  },
};

export const invitationsSlice = createSlice({
  name: "invitationsState",
  initialState,
  reducers: {
    onInvitationsToSendChanged: (
      state,
      action: PayloadAction<CsvInvitation[]>
    ) => {
      state.invitationsToSend = action.payload;
      state.invitationsErrors = [];
    },
    onProcessInvitationChanged: (state, action: PayloadAction<boolean>) => {
      state.isUploadingInvitation = action.payload;
    },
    onInvitationErrorsChanged: (
      state,
      action: PayloadAction<InvitationError[]>
    ) => {
      state.invitationsErrors = action.payload;
    },
    onCleanState: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        uploadInvitation.rejected.type,
        (
          state,
          action: PayloadAction<
            DefaultError,
            string,
            { arg: CsvInvitation },
            { code: string; message: string }
          >
        ) => {
          const index = state.invitationsToSend.findIndex((value) => {
            return value.id == action.meta.arg.id;
          });
          if (index >= 0) {
            let codeError: string = "";
            let messageError: string = "";
            state.invitationsToSend[index] = {
              ...state.invitationsToSend[index],
              was_created: false,
            };
            if (action.payload.status) {
              codeError = action.payload.status?.toString() ?? "";
              switch (action.payload.status) {
                case 400:
                    messageError =
                      "El usuario al que invitaste es actualmente el propietario del servicio";
                    break;
                case 409:
                    messageError =
                      "Ya existe una invitación de cambio de propietario";
                    break;
                case 412:
                  messageError =
                    "El usuario no es el owner del grupo de servicio";
                  break;
                case 422:
                  messageError =
                    "Por favor verifica los siguientes campos: Service account ID, emisor ID, nombre, apellido, correo o teléfono";
                  break;

                default:
                  messageError = action.payload.message ?? "";
                  break;
              }
            }
            state.invitationsErrors.push({
              message: messageError,
              code: codeError,
              owner_email: action.meta.arg.new_owner_email,
              service_account_id: action.meta.arg.service_account_id,
            });
          }
        }
      )
      .addCase(
        uploadInvitation.fulfilled.type,
        (
          state,
          action: PayloadAction<Invitation, string, { arg: CsvInvitation }>
        ) => {
          const index = state.invitationsToSend.findIndex((value) => {
            return value.id == action.meta.arg.id;
          });
          if (index >= 0) {
            state.invitationsToSend[index] = {
              ...state.invitationsToSend[index],
              was_created: true,
            };
          }
        }
      )
      .addCase(
        getInvitationsHistory.pending.type,
        (
          state,
          action: PayloadAction<
            void,
            string,
            { arg: GetInvitationsHistoryProps }
          >
        ) => {
          state.isRequestingHistory = true;
          if (action.meta.arg.reset) {
            state.invitationsHistory = {
              items: [],
              page: 0,
              pages: 0,
              size: 0,
              total: 0,
              next_page: 0,
            };
          }
        }
      )
      .addCase(getInvitationsHistory.rejected.type, (state) => {
        state.isRequestingHistory = false;
      })
      .addCase(
        getInvitationsHistory.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<Invitation>>) => {
          state.invitationsHistory = {
            ...action.payload,
            items: [...state.invitationsHistory.items, ...action.payload.items],
          };
          state.isRequestingHistory = false;
        }
      )
      .addCase(
        getUsersByPattern.pending.type,
        (
          state,
          action: PayloadAction<void, string, { arg: GetUsersByPatternProps }>
        ) => {
          state.isRequestingUsers = true;
          if (action.meta.arg.reset) {
            state.usersPagination = {
              items: [],
              page: 0,
              pages: 0,
              size: 0,
              total: 0,
              next_page: 0,
            };
          }
        }
      )
      .addCase(getUsersByPattern.rejected.type, (state) => {
        state.isRequestingUsers = false;
      })
      .addCase(
        getUsersByPattern.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<User>>) => {
          state.isRequestingUsers = false;
          state.usersPagination = {
            ...action.payload,
            items: [
              ...state.usersPagination.items,
              ...action.payload.items,
            ].filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
            ),
          };
        }
      )
      .addCase(
        getTemplateByUserId.pending.type,
        (state, action: PayloadAction<void, string, { arg: string }>) => {
          const index = state.usersPagination.items.findIndex(
            (value) => value.id === action.meta.arg
          );
          if (index > -1) {
            state.usersPagination.items[index] = {
              ...state.usersPagination.items[index],
              isRequestingTemplate: true,
            };
          }
        }
      )
      .addCase(
        getTemplateByUserId.rejected.type,
        (
          state,
          action: PayloadAction<DefaultError, string, { arg: string }>
        ) => {
          const index = state.usersPagination.items.findIndex(
            (value) => value.id === action.meta.arg
          );
          if (index > -1) {
            state.usersPagination.items[index] = {
              ...state.usersPagination.items[index],
              isRequestingTemplate: false,
            };
          }
          let options: SweetAlertOptions = {
            icon: "error",
            title: "Ups",
            text: action.payload.message ?? "",
          };
          if (action.payload.status) {
            if (action.payload.status == 422) {
              options = {
                icon: "error",
                title: "Ups",
                text: "El usuario no cuenta con ningún grupo de servicio en el cual sea propietario",
              };
            } else {
              options = {
                icon: "error",
                title: "Ups",
                text: "Ha ocurrido un error, ponte en contacto con soporte",
              };
            }
          }
          Swal.fire(options);
        }
      )
      .addCase(
        getTemplateByUserId.fulfilled.type,
        (state, action: PayloadAction<any, string, { arg: string }>) => {
          const index = state.usersPagination.items.findIndex(
            (value) => value.id === action.meta.arg
          );
          if (index > -1) {
            state.usersPagination.items[index] = {
              ...state.usersPagination.items[index],
              isRequestingTemplate: false,
            };
          }
        }
      );
  },
});

export const {
  onInvitationsToSendChanged,
  onProcessInvitationChanged,
  onInvitationErrorsChanged,
  onCleanState,
} = invitationsSlice.actions;

export default invitationsSlice.reducer;
