import { PrivateRoute, ThemeConfig } from "@enerbit/base";

import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import { LoadCsv } from "../pages/LoadCsv/LoadCsv";
import { store } from "../store/store";

export default function Routers() {
  return (
    <>
      <Provider store={store}>
        <HashRouter basename="/invitations">
          <Routes>
            <Route
              path="/"
              element={
                <ThemeConfig>
                  <PrivateRoute>
                    <section className="Container-root">
                      <Dashboard />
                    </section>
                  </PrivateRoute>
                </ThemeConfig>
              }
            />
            <Route
              path="load-csv"
              element={
                <ThemeConfig>
                  <PrivateRoute>
                    <section className="Container-root">
                      <LoadCsv />
                    </section>
                  </PrivateRoute>
                </ThemeConfig>
              }
            />
          </Routes>
        </HashRouter>
      </Provider>
    </>
  );
}
